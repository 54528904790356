import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { UserStateContext } from '../../contexts/user-state-context/user-state.context';
import useScreenSize from '../../utils/mediaQuery';
import { FormStateContext } from '../../contexts/form-state-context/form-state.context';

import backWorkBookFile from '../../assets/files/back_workbook_June_2022.pdf';

interface StatusBarProps {
  activePart: number;
}

function StatusBar({ activePart }: StatusBarProps) {
  const { userState } = useContext(UserStateContext);
  const { formState } = useContext(FormStateContext);
  const { formProcedure } = formState;
  const { userEmail } = userState;
  const { isTablet, isMobile } = useScreenSize();

  const logout = () => {
    sessionStorage.clear();
  };

  return (
    <Grid
      container
      sx={
        isMobile
          ? {
              padding: '20px 5px',
              justifyContent: 'space-between',
              borderBottom: '2px solid grey',
              alignItems: 'center',
            }
          : {
              padding: '20px 10px',
              justifyContent: 'space-between',
              borderBottom: '2px solid grey',
              alignItems: 'center',
            }
      }
    >
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* <Typography
          variant="body1"
          sx={{
            margin: `${isMobile ? '0px 5px' : '0px 10px'}`,
            fontWeight: `${activePart === 1 ? 900 : null}`,
          }}
        >
          Part One
        </Typography>
        <Typography
          variant="body1"
          sx={{
            margin: `${isMobile ? '0px 5px' : '0px 10px'}`,
            fontWeight: `${activePart === 2 ? 900 : null}`,
          }}
        >
          Part Two
        </Typography> */}
      </Grid>
      {formProcedure === 'back' && (
        <Grid item>
          <a
            style={{ fontSize: '18px', fontWeight: 'bold' }}
            href={backWorkBookFile}
            download
          >
            Download the workbook here
          </a>
        </Grid>
      )}
      <Grid item>
        <div>{userEmail}</div>
        <div>
          <Link to="/" onClick={logout}>
            log out
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}

export default StatusBar;
