import { Navigate } from 'react-router-dom';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import Layout from '../layout/Layout';
import RegisterForm from '../registerForm/RegisterForm';
import LoginForm from '../loginForm/LoginForm';
import { FormStateContext } from '../../contexts/form-state-context/form-state.context';
import useScreenSize from '../../utils/mediaQuery';

function RegisterAndLogIn() {
  const { isTablet, isMobile } = useScreenSize();
  const { formState } = useContext(FormStateContext);
  const { formProcedure } = formState;

  if (sessionStorage.getItem('userToken')) {
    return <Navigate to="/questionnaire" />;
  }

  if (!formProcedure || formProcedure === 'instructional') {
    return <Navigate to="/" />;
  }

  const procedureTitle =
    formProcedure === 'knee'
      ? 'Total Knee Replacement'
      : formProcedure === 'hip'
      ? 'Total Hip Replacement'
      : formProcedure === 'back'
      ? 'Back to Fitness Programme'
      : formProcedure === 'carpalTunnel'
      ? 'Carpal Tunnel Syndrome'
      : 'Shoulder Surgery - Rotator Cuff';

  return (
    <Layout title="register">
      <Box
        sx={{ marginBottom: 4, margin: isMobile ? '0 10px' : '' }}
        className="register-text-container"
      >
        {formProcedure === 'back' && (
          <Typography variant="h1" color="textPrimary">
            {procedureTitle}
          </Typography>
        )}
        {formProcedure !== 'back' && (
          <Typography variant="h1" color="textPrimary">
            {procedureTitle} Referral Support Facility
          </Typography>
        )}
        {formProcedure?.includes('back') ? (
          <Typography variant="body1">
            Your physiotherapist has referred you to this service as you may
            benefit from the information contained within this video series.
            This information forms part a blended program of treatment
            (self-directed learning and 1:1 physiotherapy) for patients with
            back pain. The introductory video in the series will you decide if
            this program is the option for your individual case. We will also
            ask you some simple questions to help us assess your condition.
          </Typography>
        ) : (
          <Typography variant="body1">
            This service is provided for patients for whom {procedureTitle} is
            being considered as a possible option, either now or at some time in
            the future. A consultant surgeon will show you what the operation
            involves and discuss the typical benefits, and possible problems
            associated with {procedureTitle} . This will help you decide whether
            surgery may, or may not be the right option in your individual case.
            We will also ask you to complete some simple questions to help us
            assess your{' '}
            {formProcedure === 'carpalTunnel' ? 'carpal tunnel' : formProcedure}{' '}
            condition.
          </Typography>
        )}
      </Box>
      <Grid
        container
        columns={{ xs: 6, sm: 50 }}
        direction="row"
        sx={{ justifyContent: 'space-between', padding: '0 0 40px 0' }}
      >
        <RegisterForm />

        <LoginForm />
      </Grid>
    </Layout>
  );
}

export default RegisterAndLogIn;
