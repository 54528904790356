/* eslint-disable react/no-unstable-nested-components */
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import useScreenSize from '../../../utils/mediaQuery';
import CustomButton from '../../common/customButton/CustomButton';
import HelperText from '../../helperText/HelperText';
import QuestionNumber from '../../questionNumber/QuestionNumber';
import Video from '../../video/Video';
import useSubmitAnswers from '../hooks/useSubmitAnswers';
import FormAnswers from '../interfaces/FormAnswers';
import {
  control,
  controlMobile,
  label,
  labelMobile,
  videoButton,
} from '../part.css';

interface PartProps {
  procedure: string | null;
  setActivePart: (partNumber: number) => void;
}

function PartTwo({ procedure, setActivePart }: PartProps) {
  const [formAnswers, setFormAnswers] = useState<FormAnswers>({});
  const { attemptSubmitAnswers, formUpdateSuccess, loading } =
    useSubmitAnswers();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [videoWatched, setVideoWatched] = useState(false);
  const [currentVideoNumber, setcurrentVideoNumber] = useState<number>(0);
  const [videoState, setVideoState] = useState<number>(-1);
  const [activeButtonNumber, setActiveButtonNumber] = useState<number>(0);
  const { isMobile } = useScreenSize();

  const currentBackVideo: string | undefined = Cookies.get('currentBackVideo');

  useEffect(() => {
    if (
      (procedure && document.cookie.includes(procedure)) ||
      (procedure === 'back' && currentBackVideo === '7')
    ) {
      setVideoWatched(true);
    }
  }, [procedure, setVideoWatched]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormAnswers({
      ...formAnswers,
      [name]: value,
    });
  };

  const CustomValidationsArray = [
    'havingWatchedTheVideo',
    'HWTVunderstandingCondition',
    'HWTVconfidenceManageSymptoms',
    'foundThisHelpful',
    'whatDoYouPlanToDoNow',
  ];

  if (
    procedure === 'carpalTunnel' &&
    Cookies.get('advisedCarpalSurgeryRequired') === 'no'
  ) {
    CustomValidationsArray.splice(
      CustomValidationsArray.indexOf('havingWatchedTheVideo'),
      1
    );
  }

  const submitForm = (event: any) => {
    event.preventDefault();
    const newFormArray = Object.entries(formAnswers);

    if (!formAnswers.feedback) {
      if (newFormArray.length < CustomValidationsArray.length) {
        setErrorMessage('You missed some answers, please check and try again');
      } else {
        attemptSubmitAnswers(formAnswers);
      }
    } else if (newFormArray.length < CustomValidationsArray.length + 1) {
      setErrorMessage('You missed some answers, please check and try again');
    } else {
      attemptSubmitAnswers(formAnswers);
    }
  };

  function setExpiryDate() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    const c = new Date(year + 1, month, day);
    return c;
  }

  if (formUpdateSuccess) {
    setActivePart(3);
    document.cookie = `processCompleted=${
      procedure || null
    };expires=${setExpiryDate()}`;
  }

  interface buttonProps {
    buttonNumber: number;
    setActiveButtonNumber: (args: any) => void;
    activeButtonNumber: any;
  }

  function VideoButton({
    buttonNumber,
    setActiveButtonNumber,
    activeButtonNumber,
  }: buttonProps) {
    function handleClick() {
      setVideoWatched(false);
      setcurrentVideoNumber(buttonNumber);
      setVideoState(-1);
      if (procedure === 'back') {
        document.cookie = `currentBackVideo=${buttonNumber};expires=${setExpiryDate()}`;
        setActiveButtonNumber(buttonNumber);
      }
    }
    return (
      <Typography variant="subtitle1">
        <Button
          sx={
            !currentBackVideo &&
            (buttonNumber === 0 || currentBackVideo === '0')
              ? { fontWeight: 'bold', fontSize: '18px' }
              : activeButtonNumber === buttonNumber
              ? { fontWeight: 'bold', fontSize: '18px' }
              : procedure === 'carpalTunnel'
              ? videoButton
              : { fontSize: '18px' }
          }
          onClick={handleClick}
        >
          {procedure !== 'carpalTunnel'
            ? `Video ${buttonNumber + 1}`
            : buttonNumber === 10
            ? 'Carpal Tunnel Treatment'
            : 'Additional video explaining carpal tunnel surgery'}
        </Button>
      </Typography>
    );
  }

  return (
    <>
      {procedure === 'carpalTunnel' && (
        <Grid container direction="row">
          {videoWatched && currentVideoNumber !== 11 && (
            <VideoButton
              setActiveButtonNumber={setActiveButtonNumber}
              activeButtonNumber={activeButtonNumber}
              buttonNumber={11}
            />
          )}
        </Grid>
      )}
      {procedure === 'back' && (
        <Grid container direction="row">
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={0}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={1}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={2}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={3}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={4}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={5}
          />
          <VideoButton
            setActiveButtonNumber={setActiveButtonNumber}
            activeButtonNumber={activeButtonNumber}
            buttonNumber={6}
          />
        </Grid>
      )}
      <div className="intro">
        <Video
          procedure={procedure}
          videoWatched={videoWatched}
          setVideoWatched={setVideoWatched}
          currentVideoNumber={currentVideoNumber}
          setcurrentVideoNumber={setcurrentVideoNumber}
          setVideoState={setVideoState}
          videoState={videoState}
        />
      </div>

      {(procedure === 'back' &&
        (currentVideoNumber === 6 || currentBackVideo === '7') &&
        videoWatched) ||
      (procedure !== 'back' && videoWatched) ||
      currentVideoNumber === 11 ? (
        <>
          {procedure === 'back' ? (
            <span>
              <Typography variant="h1">
                After watching all the videos...
              </Typography>
            </span>
          ) : (
            <Typography variant="h1">After watching the video...</Typography>
          )}

          <Grid
            container
            style={isMobile ? { padding: '0 5px' } : { margin: 0 }}
            direction="column"
            component="form"
            onSubmit={submitForm}
          >
            {(procedure !== 'carpalTunnel' ||
              Cookies.get('advisedCarpalSurgeryRequired') !== 'no') && (
              <>
                <FormControl sx={isMobile ? controlMobile : control} required>
                  <FormLabel sx={label} id="havingWatchedTheVideo">
                    <QuestionNumber>1</QuestionNumber>
                    {/* {procedure === 'back' ? ( */}
                    {procedure?.includes('back') ? (
                      <span>
                        Having watched all the videos, how confident are you
                        that this is the right approach for you?
                      </span>
                    ) : (
                      <span>
                        Having watched the video, how confident are you that
                        surgery is the right approach for you?{' '}
                      </span>
                    )}
                  </FormLabel>
                  <HelperText text="Not at all confident" type="before" />
                  <ToggleButtonGroup
                    orientation={`${isMobile ? `vertical` : `horizontal`}`}
                    color="primary"
                    value={formAnswers.havingWatchedTheVideo}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton name="havingWatchedTheVideo" value="1">
                      1
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="2">
                      2
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="3">
                      3
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="4">
                      4
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="5">
                      5
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="6">
                      6
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="7">
                      7
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="8">
                      8
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="9">
                      9
                    </ToggleButton>
                    <ToggleButton name="havingWatchedTheVideo" value="10">
                      10
                    </ToggleButton>
                    <HelperText text="Very confident" type="after" />
                  </ToggleButtonGroup>
                </FormControl>
                {procedure === 'carpalTunnel' && (
                  // <strong>
                  <p style={{ fontSize: '20px' }} className="info-text">
                    Now that you have watched the treatment options video, you
                    will see a link just above the video near the top of the
                    screen labelled{' '}
                    <strong>
                      ADDITIONAL VIDEO EXPLAINING CARPAL TUNNEL SURGERY
                    </strong>
                    . If you wish to learn more about the surgical approach and
                    further aspects for you to consider, then please watch this
                    now.
                  </p>
                  // </strong>
                )}
              </>
            )}
            <Divider />
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={label} id="HWTVunderstandingCondition">
                <QuestionNumber>
                  {Cookies.get('advisedCarpalSurgeryRequired') !== 'no' ? 2 : 1}
                </QuestionNumber>{' '}
                {procedure === 'back'
                  ? 'Having watched all the videos'
                  : 'Having watched the video'}
                , how well do you feel you understand your condition and any
                current treatment (including your diagnosis)?{' '}
              </FormLabel>
              <ToggleButtonGroup
                orientation={`${isMobile ? `vertical` : `horizontal`}`}
                color="primary"
                value={formAnswers.HWTVunderstandingCondition}
                exclusive
                onChange={handleChange}
              >
                <HelperText text="No understanding" type="before" />
                <ToggleButton name="HWTVunderstandingCondition" value="1">
                  1
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="2">
                  2
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="3">
                  3
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="4">
                  4
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="5">
                  5
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="6">
                  6
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="7">
                  7
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="8">
                  8
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="9">
                  9
                </ToggleButton>
                <ToggleButton name="HWTVunderstandingCondition" value="10">
                  10
                </ToggleButton>
                <HelperText text="Complete understanding" type="after" />
              </ToggleButtonGroup>
            </FormControl>
            <Divider />
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={label} id="HWTVunderstandingCondition">
                <QuestionNumber>
                  {' '}
                  {Cookies.get('advisedCarpalSurgeryRequired') !== 'no' ? 3 : 2}
                </QuestionNumber>{' '}
                {procedure === 'back' ? (
                  <span>
                    Having watched all the videos, how confident do you feel
                    about being able to manage your back pain by yourself (e.g.
                    excercises, changing lifestyle)?
                  </span>
                ) : procedure?.includes('carpal') ? (
                  <span>
                    Having watched the video, how confident do you feel about
                    being able to manage your carpal tunnel symptoms by yourself
                    (e.g. excercises, changing lifestyle)?
                  </span>
                ) : (
                  <span>
                    Having watched the video, how confident do you feel about
                    being able to manage your joint or muscle symptoms by
                    yourself (e.g. excercises, changing lifestyle)?
                  </span>
                )}
              </FormLabel>
              <ToggleButtonGroup
                orientation={`${isMobile ? `vertical` : `horizontal`}`}
                color="primary"
                value={formAnswers.HWTVconfidenceManageSymptoms}
                exclusive
                onChange={handleChange}
              >
                <HelperText text="Not at all confident" type="before" />
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="1">
                  1
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="2">
                  2
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="3">
                  3
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="4">
                  4
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="5">
                  5
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="6">
                  6
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="7">
                  7
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="8">
                  8
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="9">
                  9
                </ToggleButton>
                <ToggleButton name="HWTVconfidenceManageSymptoms" value="10">
                  10
                </ToggleButton>
                <HelperText text="Very confident" type="after" />
              </ToggleButtonGroup>
            </FormControl>

            {Cookies.get('advisedCarpalSurgeryRequired') === 'no' && (
              <>
                <Divider />
                {/* <strong> */}
                <p style={{ fontSize: '20px' }} className="info-text">
                  Now that you have watched the treatment options video, you
                  will see a link just above the video near the top of the
                  screen labelled{' '}
                  <strong>
                    ADDITIONAL VIDEO EXPLAINING CARPAL TUNNEL SURGERY
                  </strong>
                  . If you wish to learn more about the surgical approach and
                  further aspects for you to consider, then please watch this
                  now.
                </p>{' '}
                {/* </strong> */}
              </>
            )}

            <Divider />
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={label} id="HWTVunderstandingCondition">
                <QuestionNumber>
                  {' '}
                  {Cookies.get('advisedCarpalSurgeryRequired') !== 'no' ? 4 : 3}
                </QuestionNumber>
                Have you found this helpful?
              </FormLabel>
              <ToggleButtonGroup
                orientation={`${isMobile ? `vertical` : `horizontal`}`}
                color="primary"
                value={formAnswers.foundThisHelpful}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton name="foundThisHelpful" value="yes">
                  Yes
                </ToggleButton>
                <ToggleButton name="foundThisHelpful" value="no">
                  no
                </ToggleButton>
              </ToggleButtonGroup>

              {formAnswers.foundThisHelpful &&
              formAnswers.foundThisHelpful === 'no' ? (
                <p className="info-text warning">
                  Please let us know in the feedback box below why you did not
                  find this service useful.
                </p>
              ) : null}
            </FormControl>
            <Divider />
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={label} id="whatDoYouPlanToDoNow">
                <QuestionNumber>
                  {' '}
                  {Cookies.get('advisedCarpalSurgeryRequired') !== 'no' ? 5 : 4}
                </QuestionNumber>
                What do you plan to do now?
              </FormLabel>
              <ToggleButtonGroup
                orientation={`${isMobile ? `vertical` : `horizontal`}`}
                color="primary"
                value={formAnswers.whatDoYouPlanToDoNow}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton
                  name="whatDoYouPlanToDoNow"
                  value="Go to see my GP for help to manage my pain"
                >
                  Go to see my GP for help to manage my pain
                </ToggleButton>

                {procedure === 'back' ? (
                  <ToggleButton
                    name="whatDoYouPlanToDoNow"
                    value="Discuss other treatment options with my physiotherapist"
                  >
                    Discuss other treatment options with my physiotherapist
                  </ToggleButton>
                ) : procedure === 'carpalTunnel' ? (
                  <ToggleButton
                    name="whatDoYouPlanToDoNow"
                    value="Continue to explore surgical options"
                  >
                    Explore surgical options with my GP
                  </ToggleButton>
                ) : (
                  <ToggleButton
                    name="whatDoYouPlanToDoNow"
                    value="Continue to explore surgical options"
                  >
                    Continue to explore surgical options
                  </ToggleButton>
                )}
                {procedure === 'back' || procedure === 'carpalTunnel' ? (
                  <ToggleButton
                    name="whatDoYouPlanToDoNow"
                    value="I have decided to manage my condition by myself"
                  >
                    I have decided to manage my condition by myself
                  </ToggleButton>
                ) : (
                  <ToggleButton
                    name="whatDoYouPlanToDoNow"
                    value={`I have decided to manage my ${procedure} pain by myself`}
                  >
                    I have decided to manage my {procedure} pain by myself
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </FormControl>
            <Divider />
            <FormControl sx={isMobile ? controlMobile : control} required>
              <FormLabel sx={isMobile ? labelMobile : label} id="feedback">
                Your feedback is invaluable for helping us improve patient care,
                and the information we provide people with a similar condition
                to yourself. If you have time, please enter any additional
                comments below. (For example, ways we could improve this
                facility, information you would like to have received and/or
                information you found very helpful. We would also be interested
                if you are more confident in your decision.)
              </FormLabel>
              <TextareaAutosize
                name="feedback"
                value={formAnswers.feedback}
                style={{ width: `${isMobile ? 350 : 1200}`, height: 200 }}
                onChange={handleChange}
              />
            </FormControl>

            <Grid
              container
              alignItems="center"
              sx={{ justifyContent: 'space-between', padding: '20px 0 60px' }}
            >
              <Grid alignItems="center" item xs={8}>
                <CustomButton
                  onClickFunction={() => setActivePart(1)}
                  type="button"
                  disabled={loading}
                  buttonType="back"
                >
                  <span>
                    {loading ? (
                      <CircularProgress style={{ marginLeft: 15 }} size={15} />
                    ) : (
                      'Back'
                    )}
                  </span>
                </CustomButton>
              </Grid>
              <Grid
                justifyItems="center"
                xs={4}
                container
                direction="column"
                style={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end',
                  transform: 'translateY(3px)',
                }}
              >
                <CustomButton
                  type="submit"
                  disabled={loading}
                  buttonType="next"
                >
                  <span>
                    {loading ? (
                      <CircularProgress style={{ marginLeft: 15 }} size={15} />
                    ) : (
                      'Next'
                    )}
                  </span>
                </CustomButton>
                <Typography
                  variant="body2"
                  sx={{ color: 'red', marginTop: '5px' }}
                >
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        procedure === 'back' &&
        videoWatched && (
          <Typography
            variant="subtitle2"
            sx={{
              paddingBottom: '40px',
              textAlign: 'center',
              marginBottom: '0',
            }}
          >
            Remember to complete the workbook activities related to this video
            before your next physiotherapy appointment.
            <br /> The workbook can be downloaded from the link near the top of
            the page.
          </Typography>
        )
      )}
    </>
  );
}

export default PartTwo;
