import { SxProps, Theme } from '@mui/material';

export const control: SxProps<Theme> = {
  padding: '30px 0px 50px 5px',
  '&:hover': {
    backgroundColor: 'rgba(30, 92, 175, 0.05)',
  },
};

export const controlMobile: SxProps<Theme> = {
  padding: '15px 0px 25px 5px',
};

export const label: SxProps<Theme> = {
  fontSize: '20px',
  fontWeight: 400,
  marginBottom: '25px',
};
export const labelMobile: SxProps<Theme> = {
  fontSize: '16px',
  fontWeight: 400,
  marginBottom: '10px',
};

export const videoButton: SxProps<Theme> = {
  // border: 'solid blue 1px',
  color: 'white',
  fontWeight: 'bold',
  fontSize: '16px',
  backgroundColor: 'darkblue',
  // fontWeight: 400,
  marginBottom: '10px',
  ':hover': {
    border: 'solid blue 1px',
    backgroundColor: 'unset',
    color: 'blue',
  },
};
