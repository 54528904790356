import { useContext } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import { Link } from 'react-router-dom';
import KneeImage from '../../assets/images/tkr.png';
import HipImage from '../../assets/images/thr.png';
import ShoulderImage from '../../assets/images/shoulder-image.jpeg';
import backImage from '../../assets/images/back_image.png';
import instructionalImage from '../../assets/images/instructional.png';
import carpalTunnelImage from '../../assets/images/carpal-tunnel.png';
import { FormStateContext } from '../../contexts/form-state-context/form-state.context';
import { FormStateProviderActionType } from '../../contexts/form-state-context/enums/form-state-provider-action-type.enum';
import useScreenSize from '../../utils/mediaQuery';

type ProcedureSelectorProps = {
  procedure: string;
  procedureName: string;
};

function ProcedureSelector({
  procedure,
  procedureName,
}: ProcedureSelectorProps) {
  const { dispatchToForm } = useContext(FormStateContext);

  const { isTablet, isMobile } = useScreenSize();

  function handleProcedureName() {
    sessionStorage.setItem('formProcedure', procedure);
    sessionStorage.setItem('formProcedureName', procedureName);
    dispatchToForm({
      type: FormStateProviderActionType.SET_FORM_PROCEDURE,
      payload: procedure,
    });
    dispatchToForm({
      type: FormStateProviderActionType.SET_FORM_PROCEDURE_NAME,
      payload: procedureName,
    });
  }

  return (
    <Grid item xs={2} sm={6} md={6}>
      <Link
        to={`${procedure === 'instructional' ? '/instructional' : '/register'}`}
        onClick={handleProcedureName}
        style={{
          textDecoration: 'none',
        }}
      >
        <Container
          disableGutters
          sx={{
            padding: isMobile ? '10px' : '40px',
            borderRadius: '5px',
            border: '2px solid #B3D2DF',
            backgroundColor: '#D9EDF9',
          }}
        >
          <Box
            display="flex"
            sx={{
              flexDirection: isMobile ? 'column' : 'row',
              padding: isMobile ? '10px 0' : '',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '5px',
              background:
                'linear-gradient(rgb(194, 84, 140) 19%, rgb(89, 52, 122) 100%)',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h2"
              sx={{
                lineHeight: procedure === 'carpalTunnel' ? '1.1' : null,
                color: 'white',
                padding: isMobile
                  ? '0 55px 0'
                  : procedure === 'instructional' ||
                    procedure === 'carpalTunnel'
                  ? '40px 60px '
                  : '',
                fontWeight: 'bold',
                textAlign: 'center',
                margin: 0,
              }}
            >
              {procedureName}
            </Typography>
            <img
              width={isMobile || isTablet ? 150 : 190}
              style={{
                borderRadius: '2px',
                height: procedure === 'back' ? '190px' : '',
              }}
              src={
                procedure === 'knee'
                  ? KneeImage
                  : procedure === 'hip'
                  ? HipImage
                  : procedure === 'shoulder'
                  ? ShoulderImage
                  : procedure === 'instructional'
                  ? instructionalImage
                  : procedure.includes('carpal')
                  ? carpalTunnelImage
                  : backImage
              }
              alt={procedure}
            />{' '}
          </Box>
        </Container>
      </Link>
    </Grid>
  );
}

export default ProcedureSelector;
