import { useContext, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
  Alert,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import CustomButton from '../common/customButton/CustomButton';
import useScreenSize from '../../utils/mediaQuery';
import { UserStateContext } from '../../contexts/user-state-context/user-state.context';
import { FormStateContext } from '../../contexts/form-state-context/form-state.context';
import apiInstance from '../../utils/api';
import { UserStateProviderActionType } from '../../contexts/user-state-context/enums/user-state-provider-action-type.enum';

function RegisterForm() {
  const [loading, setLoading] = useState<boolean>(false);
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [passwordMismatch, setPasswordMismatch] = useState<boolean>(false);
  const [age, setAge] = useState<string>('');

  // NHS Number
  const [nhsNumberVal, setNhsNumberVal] = useState<string>('');
  const [nhsError, setNhsError] = useState<boolean>(false);
  const [nhsHelperText, setNhsHelperText] = useState<string>('');

  const { dispatchToUser } = useContext(UserStateContext);
  const { formState } = useContext(FormStateContext);

  const { formProcedure, formProcedureName } = formState;
  const navigate = useNavigate();
  const { isTablet, isMobile } = useScreenSize();

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  const handleChangeNhs = (event: any) => {
    setNhsNumberVal(event.target.value);

    const isNumber = /^\d+$/.test(event.target.value);

    if (event.target.value.length !== 0 || event.target.value.length !== 10) {
      setNhsError(true);
      setNhsHelperText('Your NHS number should be exactly 10 digits long.');
    }
    if (!isNumber) {
      setNhsError(true);
      if (event.target.value.includes(' ')) {
        setNhsHelperText('Please enter your NHS number with no spaces.');
      } else {
        setNhsHelperText(
          'Please enter a valid NHS number. Letters and other characters are not allowed.'
        );
      }
    }
    if (
      (event.target.value.length === 10 && isNumber) ||
      event.target.value.length === 0
    ) {
      setNhsError(false);
      setNhsHelperText('');
    }
  };

  if (loginSuccess && !loading) {
    navigate('/questionnaire');
  }

  const attemptRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = new FormData(event.currentTarget);
    const firstName = values.get('first-name');
    const lastName = values.get('last-name');
    const age = values.get('age');
    const email = values.get('email');
    const password = values.get('password');
    const confirmPassword = values.get('confirm-password');
    const gender = values.get('gender');
    const nhsNumber = values.get('nhsNumber');

    const rememberMeRegister = values.get('rememberMeRegister');

    setLoading(true);

    setTimeout(async () => {
      if (nhsError === true) {
        setMessage(
          'Your NHS number is invalid, please enter a valid NHS number or click "Forgotten your NHS Number".'
        );
      } else if (password !== confirmPassword) {
        setPasswordMismatch(true);
      } else {
        try {
          const { data } = await apiInstance.post('/users', {
            nhsNumber,
            firstName,
            lastName,
            age,
            email,
            gender,
            password,
            procedure: formProcedure,
            procedureName: formProcedureName,
          });
          // const accessObject = {
          //   token: data.token,
          //   expiresAt: new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
          // };
          const { token, userId, userEmail } = data.data;

          // sessionStorage.setItem('userToken', JSON.stringify(accessObject));
          // sessionStorage.setItem('userToken', JSON.stringify(token));
          sessionStorage.setItem('userToken', token);
          sessionStorage.setItem('userId', userId);
          sessionStorage.setItem('userEmail', userEmail);

          dispatchToUser({
            type: UserStateProviderActionType.SET_USER_TOKEN,
            // payload: accessObject,
            payload: token,
          });
          dispatchToUser({
            type: UserStateProviderActionType.SET_USER_ID,
            payload: userId,
          });
          dispatchToUser({
            type: UserStateProviderActionType.SET_USER_EMAIL,
            payload: userEmail,
          });
          setLoginSuccess(true);
          setLoading(false);
        } catch (error: any) {
          if (error.message === 'account-already-exists') {
            setMessage(
              `Account already exists. Please login or click "I've forgotten my password".`
            );
          } else {
            setMessage('Unexpected Server error. Please try again later');
          }
          console.log(error);
          setLoading(false);
        }
      }
      setLoading(false);
    }, 1000);
  };

  return (
    <Grid
      item
      sm={30}
      sx={{
        margin: isMobile ? '10px auto' : '0',
        bgcolor: '#C9E9E8',
        padding: '10px 25px 25px',
      }}
      component="form"
      onSubmit={attemptRegister}
    >
      <Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle1">Nice to meet you</Typography>
          <Typography variant="subtitle2">First visit</Typography>
          <Typography variant="body1">
            If this is the first time you have visited the web site, please
            enter your details in each of the boxes below and then click
            “Register Now”.
          </Typography>
        </Stack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
        >
          <TextField
            sx={{
              width: isMobile ? '100%' : '50%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
            name="first-name"
            id="first-name"
            label="First Name"
            variant="outlined"
            required
          />
          <TextField
            sx={{
              width: isMobile ? '100%' : '50%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
            name="last-name"
            id="last-name"
            label="Last Name"
            variant="outlined"
            required
          />
        </Stack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
          sx={{ alignItems: isMobile ? '' : 'center' }}
        >
          <FormControl sx={{ width: isMobile ? '100%' : '47.5%' }}>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              name="age"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChange}
            >
              <MenuItem value="36 - 40">36 - 40</MenuItem>
              <MenuItem value="41 - 45">41 - 45</MenuItem>
              <MenuItem value="46 - 50">46 - 50</MenuItem>
              <MenuItem value="51 - 55">51 - 55</MenuItem>
              <MenuItem value="56 - 60">56 - 60</MenuItem>
              <MenuItem value="61 - 65">61 - 65</MenuItem>
              <MenuItem value="66 - 70">66 - 70</MenuItem>
              <MenuItem value="71 - 75">71 - 75</MenuItem>
              <MenuItem value="76 - 80">76 - 80</MenuItem>
              <MenuItem value="81 - 85">81 - 85</MenuItem>
              <MenuItem value="86 - 90">86 - 90</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name="gender"
            >
              <FormControlLabel
                value="female"
                control={<Radio required />}
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio required />}
                label="Male"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack
          paddingBottom="10px"
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
        >
          <TextField
            sx={{
              // width: isMobile ? '100%' : '50%',
              width: '100%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
            required
            name="email"
            type="email"
            id="email"
            label="Email"
            variant="outlined"
          />
          {/* <Stack
            sx={{
              width: isMobile ? '100%' : '50%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
          > */}
          {/* <Stack>
              <TextField
                name="nhsNumber"
                id="nhsNumber"
                helperText={nhsHelperText}
                FormHelperTextProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
                onChange={handleChangeNhs}
                value={nhsNumberVal}
                error={nhsError}
                label="NHS Number"
                variant="outlined"
                placeholder="0000000000"
              />
            </Stack> */}
          {/* <Stack>
              <a
                style={{ fontSize: '15px' }}
                href="https://www.nhs.uk/nhs-services/online-services/find-nhs-number/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Forgotten your NHS number?
              </a>
            </Stack> */}
          {/* </Stack> */}
        </Stack>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={isMobile ? 0 : 4}
        >
          <TextField
            sx={{
              width: isMobile ? '100%' : '50%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
            required
            type="password"
            name="password"
            id="password"
            label="Password"
            variant="outlined"
          />
          <TextField
            sx={{
              width: isMobile ? '100%' : '50%',
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'white',
              },
            }}
            required
            error={passwordMismatch}
            helperText={`${passwordMismatch ? 'Passwords do not match' : ''}`}
            type="password"
            name="confirm-password"
            id="confirm-password"
            label="Confirm Password"
            variant="outlined"
          />
        </Stack>
        <Stack spacing={2}>
          <FormControlLabel
            style={{ margin: -10, padding: 0 }}
            control={<Checkbox required />}
            label={
              <p>
                I have read and understood the{' '}
                <a href="/terms">terms &amp; conditions</a> of using this
                website.
              </p>
            }
          />
          <FormControlLabel
            style={{ margin: -10, padding: 0 }}
            control={<Checkbox name="rememberMeRegister" />}
            label="Remember me"
          />
          <CustomButton
            type="submit"
            disabled={loading}
            buttonType="primaryButton"
          >
            <span>
              {loading ? (
                <CircularProgress style={{ marginLeft: 15 }} size={15} />
              ) : (
                <>
                  <span>Register</span>
                  <LockIcon
                    sx={{ marginLeft: '10px', transform: 'translateY(3px)' }}
                  />
                </>
              )}
            </span>
          </CustomButton>
          {message && (
            <Alert
              style={{ width: '100%' }}
              sx={{
                margin: '15px 0',
                padding: '6px 5px',
              }}
              severity="warning"
            >
              {message}
            </Alert>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
}

export default RegisterForm;
