import { Alert, Box, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from '../layout/Layout';
import ProcedureSelector from '../procedureSelector/ProcedureSelector';

function Home() {
  return (
    <Layout title="Home">
      <Box>
        <Typography variant="h1" color="textPrimary">
          Online Medical Decision Aids
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ fontSize: '20px' }}
        >
          <strong>Helping us make the right decision together</strong>
        </Typography>
        <Box sx={{ margin: '30px 0' }}>
          <Typography variant="body1">
            Our Dorset Medical Decision Aids are provided for patients
            considering selected types of surgery, either now or at some time in
            the future. These interactive programs will show you what the
            operation involves and discuss the typical benefits, and possible
            associated problems.
          </Typography>
          <Typography variant="body1">
            This will help you decide whether surgery may, or may not be the
            right option in your individual case. We will also ask you to
            complete some simple questions to help us assess your condition. We
            hope you find it useful.
          </Typography>
        </Box>
        <Alert
          sx={{
            bgcolor: '#CFF2D6',
            border: 'solid #8EC49C 3px',
            fontSize: '16px',
            padding: '15px',
            marginBottom: '30px',
          }}
          variant="outlined"
          severity="info"
          color="success"
        >
          Simply select the procedure below, where upon you will be asked to
          sign up, or sign in if you have already registered.
        </Alert>
      </Box>
      <Typography
        variant="h3"
        color="textSecondary"
        sx={{ padding: '20px 0 30px 0' }}
      >
        <u>Surgical Procedure Decisions Aids</u>
      </Typography>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <ProcedureSelector
          procedure="knee"
          procedureName="Total Knee Replacement"
        />
        <ProcedureSelector
          procedure="hip"
          procedureName="Total Hip Replacement"
        />
        <ProcedureSelector
          procedure="shoulder"
          procedureName="Surgery For Rotator Cuff Related Shoulder Pain"
        />
      </Grid>
      <Typography
        variant="h3"
        color="textSecondary"
        sx={{ padding: '60px 0 30px 0' }}
      >
        <u>Condition Management Decisions Aids</u>
      </Typography>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={{ xs: 1, sm: 2 }}
      >
        <ProcedureSelector
          procedure="carpalTunnel"
          procedureName="Carpal Tunnel Syndrome"
        />
      </Grid>
      <Typography
        variant="h3"
        color="textSecondary"
        sx={{ padding: '40px 0 30px 0' }}
      >
        <u>Back Pain Rehabilitation Programmme</u>
      </Typography>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ paddingBottom: '30px' }}
      >
        {' '}
        <ProcedureSelector
          procedure="back"
          procedureName="Back to Fitness Programme"
        />
      </Grid>

      <Typography
        variant="h3"
        color="textSecondary"
        sx={{ padding: '40px 0 30px 0' }}
      >
        <u>
          An Introduction to Shared Decision Aids for Healthcare Professionals
        </u>
      </Typography>
      <Grid
        container
        columns={{ xs: 2, sm: 12, md: 12 }}
        spacing={{ xs: 1, sm: 2 }}
        sx={{ paddingBottom: '30px' }}
      >
        {' '}
        <ProcedureSelector
          procedure="instructional"
          procedureName="Instructional Video"
        />
      </Grid>
    </Layout>
  );
}

export default Home;
