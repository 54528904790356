import { Link } from 'react-router-dom';
import { Container, Grid, Tooltip } from '@mui/material';
import dorsetLogo from '../../../assets/logos/dorset-logo.jpg';
import medicalDecisionsLogo from '../../../assets/logos/logo.png';
import medicalConsentLogo from '../../../assets/logos/Medical_Consent_Logo_Transparent.png';
import phone from '../../../assets/icons/phone.svg';
import envelope from '../../../assets/icons/envelope.svg';

import useScreenSize from '../../../utils/mediaQuery';

function Header() {
  const { isTablet, isMobile } = useScreenSize();

  return (
    <Container
      sx={{
        padding: isTablet ? '30px 10px' : '25px 0 10px',
        textAlign: isTablet ? 'center' : null,
      }}
      disableGutters
    >
      <Grid
        container
        columns={{ xs: 6, sm: 10, md: 16 }}
        spacing={{ xs: 1, md: 8 }}
      >
        <Grid sx={{ paddingTop: '52px!important' }} item xs={2} sm={2} md={3}>
          <img
            src={dorsetLogo}
            alt="Medical Decisions"
            id="dorset-logo"
            width={110}
          />
        </Grid>
        <Grid sx={{ paddingLeft: '0px!important' }} item xs={2} sm={2} md={3}>
          <Link to="/">
            <img
              src={medicalDecisionsLogo}
              alt="Medical Decisions"
              width={isMobile ? 100 : isTablet ? 120 : 190}
            />
          </Link>
        </Grid>
        <Grid sx={{ paddingLeft: '10px!important' }} item xs={2} sm={2} md={3}>
          <img
            src={medicalConsentLogo}
            alt="Medical Consent"
            width={isMobile ? 100 : isTablet ? 120 : 190}
          />
        </Grid>
        <Tooltip
          sx={{
            fontSize: isMobile ? '12px' : '20px',
          }}
          title={
            <span
              style={{
                fontSize: '15px',
              }}
            >
              Please note the phone line and email address are for matters
              concerning this website only, and we cannot offer any clinical
              advice. For clinical advice, please contact your GP or physician.
            </span>
          }
        >
          <Grid
            item
            xs={6}
            sm={4}
            md={7}
            sx={{
              paddingTop: '5px',
              textAlign: isMobile ? 'center' : 'end',
              fontSize: isTablet || isMobile ? '12px' : '16px',
            }}
          >
            <Link
              style={{
                margin: '10px 0px',
                textDecoration: 'none',
                color: 'black',
              }}
              to="tel:+44 (0)1202 798286"
            >
              <img
                style={{ width: '20px', marginRight: '10px' }}
                src={phone}
                alt="Phone"
              />
              +44 (0)1202 798 286
            </Link>
            <br />
            <Link
              style={{
                margin: '10px 0px',
                textDecoration: 'none',
                color: 'black',
              }}
              to="mailto:support@medicalconsent.com"
            >
              <img
                style={{ width: '20px', marginRight: '10px' }}
                src={envelope}
                alt="envelope"
              />
              support@medicalconsent.co.uk
            </Link>
          </Grid>
        </Tooltip>
      </Grid>
    </Container>
  );
}

export default Header;
