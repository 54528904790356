/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { Grid } from '@mui/material';
import Cookies from 'js-cookie';
import PlayButton from '../../assets/icons/play-button.png';
import replayButton from '../../assets/icons/replay-button.png';
import continueButton from '../../assets/icons/continue-button.png';

import './video.css';
import useScreenSize from '../../utils/mediaQuery';

type playerProps = {
  videoWatched: boolean;
  setVideoWatched: (arg: boolean) => void;
  procedure: string | null;
  currentVideoNumber: number;
  setcurrentVideoNumber: (arg: number) => void;
  videoState: number;
  setVideoState: (arg: number) => void;
};

const videoArea = {
  background:
    'linear-gradient(180deg,rgba(209, 72, 137, 1) 27%,rgba(82, 58, 136, 1) 97%)',
  width: '100%',
  height: '100%',
  position: 'relative',
  paddingBottom: '56.25%' /* 16:9 */,
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
};

const videoPlay = {
  position: 'absolute',
  zIndex: '2',
  margin: 'auto',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const videoPlayInstructions = {
  textAlign: 'center',
  position: 'absolute',
  width: '100%',
  color: '#fff',
  fontWeight: '500',
  fontSize: '2.5em',
  bottom: '5vw',
};

const videoTitleStyles = {
  width: '80%',
  textAlign: 'center',
  fontSize: '1.8em',
  lineHeight: '6vw',
  fontWeight: 'bold',
  color: '#fff',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '3vw',
  margin: '0 auto',
};

const videoEndedOptions = {
  zIndex: '2',
  transition: 'all 0.5s linear',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function Video({
  videoWatched,
  setVideoWatched,
  procedure,
  currentVideoNumber,
  setcurrentVideoNumber,
  videoState,
  setVideoState,
}: playerProps) {
  const { isTablet, isMobile } = useScreenSize();

  const [videoId, setVideoId] = useState<string>('');
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [videoTitle, setVideoTitle] = useState<string>('');
  const [player, setPlayer] = useState<any>();

  const backVideoIds = [
    'RU4Gh8PpmGs',
    'tGvMrvjN_QE',
    '1J1HVCnLpzI',
    'N3DSXIALLuc',
    '_Ni3eOr3Mj0',
    '6uIW7R1qZAk',
    'BEn_xXbfgS4',
  ];

  const backVideoTitles = [
    'Part 1 - Introduction',
    'Part 2 - Understanding Pain',
    'Part 3 - The effects of worrying about pain',
    'Part 4 - Challenging unhelpful thoughts',
    'Part 5 - Benefits of Exercise',
    'Part 6 - How to set a baseline & pace your progress',
    'Part 7 - Managing flare ups and the importance of sleep',
  ];

  const currentBackVideo: string | undefined = Cookies.get('currentBackVideo');

  const currentBackVideoAsNumber = currentBackVideo
    ? parseInt(currentBackVideo, 10)
    : 0;

  useEffect(() => {
    if (procedure === 'back') {
      setVideoTitle(
        `Back to Fitness  ${
          !currentBackVideo
            ? backVideoTitles[0]
            : backVideoTitles[currentBackVideoAsNumber]
        }`
      );
      if (
        !document.cookie.includes('currentBackVideo') ||
        currentBackVideo === '0'
      ) {
        setVideoId('RU4Gh8PpmGs');
      } else if (currentBackVideoAsNumber < 6) {
        setVideoId(backVideoIds[currentBackVideoAsNumber]);
      } else {
        setVideoId('BEn_xXbfgS4');
      }
    }
    switch (procedure) {
      case 'knee':
        setVideoId('NDyufzgH8Nc');
        setVideoTitle('Is Knee Replacement Surgery right for me?');
        break;
      case 'hip':
        setVideoId('otiYKixLuqs');
        setVideoTitle('Is Hip Replacement Surgery right for me?');
        break;
      case 'shoulder':
        setVideoId('HkoNFVCkiGk');
        setVideoTitle('Medical Decisions Dorset Shoulder Surgery Information');
        break;
      case 'carpalTunnel':
        setVideoId('jCIvns6GX6M');
        setVideoTitle('Is Carpal Tunnel Treatment right for me?');
        break;
      default:
    }

    if (currentVideoNumber === 11) {
      setVideoId('AwGFDQRL87M');
      setVideoTitle('Is Carpal Tunnel Surgery right for me?');
      const opts = {
        playerVars: {
          start: 90,
        },
      };
    }
  }, [currentVideoNumber, videoWatched]);

  function handleReady(event: any) {
    setVideoLoaded(true);
    setPlayer(event.target);
  }

  const play = () => {
    player.playVideo();
    setVideoState(1);
  };

  const replay = () => {
    player.playVideo();
    setVideoState(1);
    const currentBackVideo: string | undefined =
      Cookies.get('currentBackVideo');
    document.cookie = `currentBackVideo=${
      currentBackVideo ? parseInt(currentBackVideo, 10) - 1 : null
    };expires=${setExpiryDate()}`;
  };

  function setExpiryDate() {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    const c = new Date(year + 1, month, day);
    return c;
  }

  function handleStateChange(event: any) {
    const playerState = event.target.getPlayerState();

    if (playerState === 0) {
      if (procedure === 'back') {
        setVideoWatched(true);
        setVideoState(0);

        let nextVideoNumber = 0;
        if (!currentBackVideo) {
          nextVideoNumber = currentVideoNumber + 1;
        } else if (parseInt(currentBackVideo, 10) < 7) {
          nextVideoNumber = parseInt(currentBackVideo, 10) + 1;
        } else {
          nextVideoNumber = parseInt(currentBackVideo, 10);
        }
        document.cookie = `currentBackVideo=${nextVideoNumber};expires=${setExpiryDate()}`;
      } else {
        setVideoWatched(true);
        setVideoState(0);
        document.cookie = `videowatched=${procedure};expires=${setExpiryDate()}`;
      }
    }
  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: currentVideoNumber === 11 ? { start: 221 } : {},
  };

  return (
    <>
      <Grid sx={videoArea}>
        {!videoWatched && videoLoaded && videoState === -1 && (
          <>
            <Grid sx={videoPlay} onClick={play}>
              <img alt="" style={{ width: '15vw' }} src={PlayButton} />
            </Grid>
            <Grid sx={videoPlayInstructions}>Press play to get started</Grid>
          </>
        )}

        {videoState === 0 || videoState === -1 ? (
          <Grid
            sx={videoTitleStyles}
            style={{
              fontSize: isMobile ? '1em' : isTablet ? '3em' : '',
            }}
          >
            {videoTitle}
          </Grid>
        ) : null}
        {videoState === 0 || (videoWatched && videoState === -1) ? (
          <Grid sx={videoEndedOptions}>
            <img
              src={replayButton}
              alt="Replay Video"
              onClick={procedure === 'back' ? replay : play}
              style={{
                width: isMobile ? '8em' : isTablet ? '12em' : '20em',
                margin: '0 3vw',
                cursor: 'pointer',
              }}
            />
            {procedure !== 'back' && (
              <img
                style={{
                  width: isMobile ? '8em' : isTablet ? '12em' : '20em',
                  margin: '0 3vw',
                  cursor: 'pointer',
                }}
                src={continueButton}
                onClick={() => {
                  window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: 800,
                  });
                }}
                alt="Continue"
              />
            )}
          </Grid>
        ) : null}

        <YouTube
          opts={opts}
          videoId={videoId}
          onReady={(event) => handleReady(event)}
          onStateChange={(event) => handleStateChange(event)}
          className="video"
          containerClassName={`video-wrapper ${
            videoState === 0 || videoState === -1 ? '' : 'active'
          }`}
        />
      </Grid>
      <div style={{ height: '50px' }} />
    </>
  );
}

export default Video;
